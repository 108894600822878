import React from 'react';
import NewLayout from '../layout/newLayout';
import NewArticle from '../components/newArticle';
import CtaBanner from "../components/ctaBanner";
import Title from "../components/title";
import SEO from "../components/seo";
import { slice as _slice } from 'lodash';
import styles from '../styles/scss/pages/blog.module.scss';
import InfiniteScroll from 'react-infinite-scroll-component';
import EventAnchor from '../components/eventAnchor';

const pageSize = 9;

const filterBlogs = function filterBlogs(blogs) {
    const out = blogs.filter(blog => {
        let matchCategory = true;
        return matchCategory;

    });
    return out;
};

const NewBlogTemplate = ({
    pageContext
}) => {

    // States
    const [currentPage, setCurrentPage] = React.useState(0);
    const [blogs] = React.useState(() => {
        return pageContext.blogs.sort((a, b) => {
            return a.dateOfArticle < b.dateOfArticle ? 1 : -1
        });
    });

    const handleInfiniteScrollNext = React.useCallback(() => {
        setCurrentPage(s => s + 1);
    }, []);

    // Memos
    const {
        pagedBlogs,
        pages
    } = React.useMemo(() => {
        const out = {
            pagedBlogs: [],
            pages: 0
        };

        if (blogs && blogs.length) {
            // Filter blogs
            const filteredBlogs = filterBlogs(blogs);

            // Paginate blogs
            const paginationStart = currentPage * pageSize;
            const paginationEnd = (currentPage * pageSize) + pageSize;
            const newpagedBlogs = _slice(filteredBlogs, paginationStart, paginationEnd);
            const previouspagedBlogs = _slice(blogs, 0, paginationStart);

            out.pagedBlogs = currentPage === 0 ? newpagedBlogs : [...previouspagedBlogs, ...newpagedBlogs];

            // Calculate Pages
            out.pages = Math.ceil(blogs.length / pageSize);
        }

        return out;
    }, [
        currentPage,
        blogs,
    ]);

    // Disable SSR on this page
    if (typeof window === 'undefined') {
        return null;
    }

    return (
        <NewLayout>
            <SEO page={pageContext.page} />
            <div className='d-flex flex-column flex-fill'>

                <InfiniteScroll
                    className={styles.infiniteContainer}
                    dataLength={pagedBlogs.length}
                    hasMore={currentPage <= (pages - 1)}
                    loader={() => (
                        <h4>
                            Loading...
                        </h4>
                    )}
                    endMessage={
                        <p className={styles.endMessage}>
                            There are no more blogs
                        </p>
                    }
                >

                    <div className={styles.topContentWrapper}>
                        <div
                            className={styles.topContent}
                        >
                            <Title
                                className={styles.sitetitleNewLayout}
                                value="WD-40® Brand Blog"
                            />

                        </div>
                    </div>

                    <div className={styles.container}>
                        <div className={styles.items}>
                            {pagedBlogs.length > 0 && pagedBlogs.map(blog => (
                                <div key={blog.id} className={styles.blogItem}>
                                    <NewArticle
                                        article={blog}
                                        imageClass={styles.itemImage}
                                        bodyClass={styles.itemBody}
                                    />
                                </div>
                            ))}
                            {currentPage < (pages - 1) &&
                                <div className={styles.seeMoreAnchor}>
                                    <EventAnchor
                                        text="See more blog posts >>"
                                        event={handleInfiniteScrollNext}
                                        condensed
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </InfiniteScroll>

            </div>

            <CtaBanner
                text="WANT TO GET MORE TIPS AND TRICKS?"
                buttonText="SUBSCRIBE TO THE NEWSLETTER"
                buttonUrl="/signup/"
            />
        </NewLayout>
    )
}

export default React.memo(NewBlogTemplate);